const states =[
    {
        "id": 0,
        "name": "Connecticut",
        "supported": "Y"
    },
    {
        "id": 1,
        "name": "Maine",
        "supported": "Y"
    },
    {
        "id": 2,
        "name": "Massachusetts",
        "supported": "Y"
    },
    {
        "id": 3,
        "name": "New Hampshire",
        "supported": "Y"
    },
    {
        "id": 4,
        "name": "Rhode Island",
        "supported": "Y"
    },
    {
        "id": 5,
        "name": "Vermont",
        "supported": "Y"
    },
]

export default states;