import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CreditScoreIcon from '@mui/icons-material/CreditScore';


export default function JadeSolarBenefitList() {
  return (
    <List id="jade-benefit-list">
      <ListItem>
        <ListItemAvatar sx={{display:'block'}}>
          <Avatar>
            <ShoppingCartIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Purchase with ease" secondary="Jade Solar provides three flexible purchasing options to suit your needs: 
        Finance, Lease, or Cash. Choose the one that's right for you and start saving with solar energy today!" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <CreditScoreIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Apply with confidence" secondary="Jade Solar welcomes all credit backgrounds, making solar energy accessible for everyone." />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <EmojiEmotionsIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Live with peace of mind" secondary="All of our solar systems come with a comprehensive warranty for your peace of mind." />
      </ListItem>
      {/* <ListItem>
        <ListItemAvatar>
          <Avatar>
            <AttachMoneyIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Recommend with incentives" secondary="Discover our referral program where you can earn up to $2,000 for every property owner you refer to Jade Solar. Start earning today!" />
      </ListItem> */}
    </List>
  );
}