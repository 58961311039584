import React from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import benefits from "../constants/benefits";


function createListitem(props){

    const listItemOverrides = {
      typography: {
        fontSize: '1.2rem',
        '@media screen and  (max-width: 900px)': {
          fontSize: '1rem'
        },
      },
    }
  
    return(
    <ListItem  key={props.id} sx={{fontSize:'1vw'}} >
        <ListItemAvatar>
         
            <DoneIcon sx={{transform: 'scale(1.8)', fill:'#34B233'}}/>
          
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={listItemOverrides} 
          primary={<a href="#benefits" style={{color:'#F6F1F1'}}>{props.benefit}</a>}
        />
      </ListItem>
      );

}


export default function BenifitsList(){
    const typographyOverrides = {
      typography: {
        fontSize: '2rem',
        mt: 4, 
        mb: 2,
        marginBottom: 0,
        '@media screen and  (max-width: 900px)': {
          fontSize: '1.2rem'
        },
      },
    }

    
    return (
        <Grid item xs={12} md={6}>
          <Typography sx={typographyOverrides} variant="h6" component="div">
            Why Go Solar? 
            <p style={{fontSize:'1rem', marginTop: 0, opacity: '.8'}}>Click a benefit to learn more</p>
          </Typography>
            <List>
              {benefits.map(createListitem)}
            </List>
        </Grid>
    );
}