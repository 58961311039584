import * as React from 'react';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { firstName } from './Form';


export default function FormCompleteGrow(props) {
  const [checked, setChecked] = React.useState(props.checked);
  var [fName, setFName] = React.useState(firstName);
  const icon = (
    <div>
      <CheckCircleOutlineIcon sx={{transform: 'scale(3.0)', fill:'#34B233'}}/>
      <h3 style={{color:'#555554'}}>Thank you, {fName}!<br/> A Jade Solar representative will contact you within 48 hours.</h3>
    </div>
  );


  return (
    <Box sx={{ height: 180 }}>
      <Box sx={{ display: 'flex' }}>
        {/* Conditionally applies the timeout prop to change the entry speed. */}
        <Grow
          id="complete-msg-div" 
          in={checked}
          style={{ transformOrigin: '0 0 0' }}
          {...(checked ? { timeout: 1000 } : {})}
        >
          {icon}
        </Grow>
      </Box>
    </Box>
  );
}
