import React from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import validator from 'validator';
import states from "../constants/states";
import sources from "../constants/source";
import utilAvg from "../constants/utilAvg";
import FormCompleteGrow from "./FormCompleteGrow";
import { leadPost } from "../lead-api";

let firstName = "";

export default function Form() {


    function emptyInput() {
        return (
            {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                postal: "",
                state: "",
                source: "",
                utilAvg: ""
            }
        );
    };

    function emptyInputValidated() {
        return (
            {
                firstName: true,
                lastName: true,
                email: true,
                phone: true,
                postal: true
            }
        );
    };

    const formInput = emptyInput;
    const inputValidated = emptyInputValidated;

    const [input, setInput] = React.useState(formInput);
    const [inputValid, setInputValid] = React.useState(inputValidated);

    function validateInputs() {
        var valid = true;
        if (validator.isEmpty(input.firstName) || !validator.isAlphanumeric(input.firstName)) {
            setInputValid(prevValue => { return { ...prevValue, firstName: false }; });
            valid = false;
        } else {
            setInputValid(prevValue => { return { ...prevValue, fName: true }; });
        }

        if (!validator.isEmpty(input.lastName) && !validator.isAlphanumeric(input.lastName)) {
            setInputValid(prevValue => { return { ...prevValue, lastName: false }; });
            valid = false;
        } else {
            setInputValid(prevValue => { return { ...prevValue, lName: true }; });
        }

        if (validator.isEmpty(input.email) || !validator.isEmail(input.email)) {
            setInputValid(prevValue => { return { ...prevValue, email: false }; });
            valid = false;
        } else {
            setInputValid(prevValue => { return { ...prevValue, email: true }; });
        }

        if (validator.isEmpty(input.phone) || !validator.isMobilePhone(input.phone)) {
            setInputValid(prevValue => { return { ...prevValue, phone: false }; });
            valid = false;
        } else {
            setInputValid(prevValue => { return { ...prevValue, phone: true }; });
        }

        if (!validator.isEmpty(input.postal) && !validator.isNumeric(input.postal)) {
            setInputValid(prevValue => { return { ...prevValue, postal: false }; });
            valid = false;
        } else {
            setInputValid(prevValue => { return { ...prevValue, postal: true }; });
        }

        return valid;
    };

    function handleChange(event) {
        const { name, value } = event.target;
        setInput(prevValue => {
            return {
                ...prevValue,
                [name]: value
            };
        });
    };

    const [formSubmitted, setformSubmitted] = React.useState(false);

    
    function handleSubmit(event) {
        if (validateInputs()) {
            leadPost("/v1/add-lead", input);
            setInput(emptyInput);
            setformSubmitted(true);
            firstName = input.firstName;
        }
        event.preventDefault();
    }

    return (
        <div>
            <form id="form-grid" onSubmit={handleSubmit} style={{ display: formSubmitted && 'none' }}>
                <TextField
                    error={!inputValid.firstName}
                    id="first-name-input"
                    label="First Name"
                    type="text"
                    name="firstName"
                    value={input.firstName}
                    onChange={handleChange}
                    required
                    helperText={!inputValid.firstName && 'First name is invalid'}
                />

                <TextField
                    error={!inputValid.lastName}
                    id="last-name-input"
                    label="Last Name"
                    type="text"
                    name="lastName"
                    value={input.lastName}
                    onChange={handleChange}
                    helperText={!inputValid.lastName && 'Last name is invalid'}
                />

                <TextField
                    error={!inputValid.email}
                    id="email-input"
                    label="Email"
                    type="text"
                    name="email"
                    inputProps={{ inputMode: 'email' }}
                    value={input.email}
                    onChange={handleChange}
                    required
                    helperText={!inputValid.email && 'Email is invalid'}

                />

                <TextField
                    error={!inputValid.phone}
                    id="phone-input"
                    label="Phone Number"
                    type="text"
                    name="phone"
                    inputProps={{ inputMode: 'tel' }}
                    value={input.phone}
                    onChange={handleChange}
                    required
                    helperText={!inputValid.phone && 'Phone numberis invalid'}


                />

                <TextField
                    error={!inputValid.postal}
                    id="zip-input"
                    label="Postal Code"
                    type="text"
                    name="postal"
                    inputProps={{ inputMode: 'numeric' }}
                    value={input.postal}
                    onChange={handleChange}
                    helperText={!inputValid.postal && 'Postal code is invalid'}
                />

                <TextField
                    id="state-select"
                    select
                    label="State"
                    defaultValue=""
                    helperText="Jade Solar proudly supports the New England Region"
                    name="state"
                    value={input.state}
                    onChange={handleChange}
                >
                    {states.map((option) => (
                        <MenuItem key={option.id} value={option.name} sx={{ color: 'black' }}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    id="source-select"
                    select
                    label="How did you hear about us?"
                    defaultValue=""
                    name="source"
                    value={input.source}
                    onChange={handleChange}
                >
                    {sources.map((option) => (
                        <MenuItem key={option.id} value={option.source} sx={{ color: 'black' }}>
                            {option.source}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    id="bill-select"
                    select
                    label="Estimated average utility bill?"
                    defaultValue=""
                    name="utilAvg"
                    value={input.utilAvg}
                    onChange={handleChange}
                >
                    {utilAvg.map((option) => (
                        <MenuItem key={option.id} value={option.average} sx={{ color: 'black' }}>
                            {option.average}
                        </MenuItem>
                    ))}
                </TextField>

                {/* <FormControl id="owner-radio">
                    <FormLabel  sx={{color:"#555554"}}>Do you own this property?</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                        row
                    >
                        <FormControlLabel value="female" control={<Radio />} label={<Typography style={{color:"#555554"}}>Yes</Typography>} />
                        <FormControlLabel value="male" control={<Radio  />} label={<Typography style={{color:"#555554"}}>No</Typography>} />
                    </RadioGroup>
                </FormControl> */}

                <Button id="form-submit" variant="contained" sx={{ backgroundColor: '#19A7CE', width: '100%', display: 'block' }} type="submit">REQUEST A FREE CONSULTATION</Button>
            </form>


            {formSubmitted && <FormCompleteGrow checked={true} />}
        </div>


    );
}

export { firstName };