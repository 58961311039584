import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  textAlign:'left',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div id="accordion-div" >
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Significant monthly cost savings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            At Jade Solar, we're committed to finding a solution where you'll end up paying less for solar than you currently do for your electricity bill! 
            Imagine saving a bundle by slashing your utility costs, maybe even eliminating them entirely.
            It's more than just saving money—it's an investment that keeps on giving back, whether you're a homeowner or running a business.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Increased property value</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Homes equipped with solar energy systems often have a higher perceived value in the real estate market.
            Studies and real-world data show that solar panels can actually boost a home's selling price, thanks to energy savings and growing market demand.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Government incentives and rebates</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Residents in all states are eligible for a 30% federal tax credit. In addition to this federal benefit, there are various state and municipal programs available. 
            For example, New Hampshire offers full net metering credits, Massachusetts has the SMART program, and Rhode Island features the REG and REF initiatives. We will
             guide you through all the incentives that apply to you and include them in your personalized proposal,
            complete with a financial model projecting your costs over the next 25 years. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>Positive enviromental impact</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Going solar is a great way to reduce your carbon footprint! Solar energy is clean and renewable,
            helping to cut down on greenhouse gas emissions and air pollution. It's a fantastic step toward a healthier planet!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} sx={{}}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography>Energy independence</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Say goodbye to unpredictable energy bills! By generating your own electricity, 
            you become less dependent on utility companies and can avoid those rising energy prices.
            Plus, with a solar battery, you'll have power even during outages.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}