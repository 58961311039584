const steps =[
    {
        "id": 0,
        "num": "1",
        "info": "Submit Consultation Form"
    },
    {
        "id": 1,
        "num": "2",
        "info": "Introduction Call"
    },
    {
        "id": 2,
        "num": "3",
        "info": "Cost/Benefit Analysis Conducted"
    },
    {
        "id": 3,
       "num": "4",
        "info": "Site Assessment"
    },
    {
        "id": 4,
       "num": "5",
        "info": "Professional Installation"
    },
    {
        "id": 5,
        "num": "6",
        "info": "Begin Saving Money!"
    },
]

export default steps;