const utilAvg =[
    {
        "id": 0,
        "average": "$0-$100"
    },
    {
        "id": 1,
        "average": "$100-$200"
    },
    {
        "id": 2,
        "average": "$200-$300"
    },
    {
        "id": 3,
        "average": "$300-$400"
    },
    {
        "id": 4,
        "average": "$500+"
    },
]

export default utilAvg;