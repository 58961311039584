import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from './pages/Home';
import { Health } from './pages/Health';
import { NotFound } from './pages/NotFound';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route  index element={<Home />}/>
        <Route path="/health" element={<Health />}/>
        {/* <Route path="*" element={<NotFound />}/> */}
      </Routes>
    </BrowserRouter>
    
  );
}

export default App;
