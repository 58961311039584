import DrawerAppBar from '../componets/DrawerAppBar.jsx';
import BenifitsList from '../componets/BenifitsList.jsx';
import { Button } from '@mui/material';
import Form from '../componets/Form.jsx';
import BenefitsAccordion from '../componets/BenefitsAccordion.jsx';
import Process from '../componets/Process.jsx';
import CopyrightIcon from '@mui/icons-material/Copyright';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import JadeSolarBenefitList from '../componets/JadeSolarBenefitList.jsx';

export function Home(){
  return(
    <div className="App" id="app-div">
      <DrawerAppBar/>
      <section id="intro" className="light-text">
        <div id="background-intro">
        <div id="intro-div">
          <div className="flex-container">
            <h1 id="heading">Harnessing The Sun,<br/> Empowering Your Future. </h1>
            <div>
              <BenifitsList/>
              <Button variant="contained" sx={{backgroundColor: '#19A7CE', width:'100%', display:'block'}}>
                  <a className="light-text" href="#first-name-input">REQUEST A <span style={{}}>FREE</span> CONSULTATION</a>
                </Button>
            </div>
          </div>
        </div>
        </div>
      </section>

      <section id="form">
        <div id="form-div">
          <div id="form-heading">
            <h2 style={{color: '#555554'}}>Interested In Going Solar?</h2>
            <h3 style={{color: '#555554'}}>Fill out the contact form to learn more.</h3>
          </div>
          <div id="form-container">
            <Form/>
          </div>
        </div>
      </section>

      <section id="benefits">
        <div id="benefits-flex">
          <div id="benefits-div">
            <h2 style={{color: '#146C94'}} id="benefits-heading">Why should you go solar?</h2>
            <BenefitsAccordion  />
          </div>
      

          <div id="about-div">
            <h2 style={{color: '#146C94'}}>About Jade Solar</h2>
            <p style={{color: '#555554'}}>
              Jade Solar is a local, family-owned business established in 2018. We take pride in having served hundreds of homes in the New England region, 
              ensuring customer satisfaction throughout their entire solar journey. At Jade Solar, we uphold three core principles: <em>Integrity, Transparency, and Dedication. </em>
              Customer satisfaction is our top priority, and by adhering to these principles at every step of the process, we aim to guarantee just that. With years of experience in the solar industry,
              our team brings unparalleled expertise to every project. We offer top-quality solar products and installations. 
              Our commitment to excellence guarantees reliable performance and long-term savings for our valued customers.
            </p>
            <h4>Additional benefits when choosing Jade Solar</h4>
            <JadeSolarBenefitList/>
          
          </div>

          <div id="process-div">
            <h2 style={{color: '#146C94'}}>Our Process</h2>
            <Process/>

          </div>
        </div>
      </section>

      <footer>
        <div className="left-element">
          <div id="copyright-div">
            <CopyrightIcon/>
            <p>Copyright {new Date().getFullYear()} Jade Solar LLC.</p>
          </div>
          <p id="contact">Contact us: <span id="email-span">sales@jadesolarllc.com</span></p>
          <img id="ff-img" src="https://apollosolarcompany.com/wp-content/uploads/2022/04/ff-logo-iad-black-2@4x.png" alt="freedom-forever-logo"/>
        </div>
        
        <div id="links-footer" className="right-element">
          <a href="#intro">Home</a>
          <a href="#form">Consultation Form</a>
          <a href="#benefits">Benefits</a>
          <a href="#about-div">About Jade Solar</a>
          <a href="#process-div">Our Process</a>
          {/* <div id="social-div">
            <a href="https://www.instagram.com/gojadesolar?igsh=MXRmcjMwdHVoMzhkOA=="><InstagramIcon/></a>
            <a><FacebookIcon/></a>
          </div> */}
        </div>

      </footer>
      
    </div>
  );
}