
import axios from 'axios';

const url = process.env.REACT_APP_LEAD_API_URL;
const api = axios.create({
    baseURL: url,
    headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

function leadPost(endpoint, body){
    var response;
    api.post(endpoint, body)
    .then(res => {response = res})
    .catch(error => {});

    return response;
}

export {leadPost};
