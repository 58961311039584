const benefits = [
    {
        "id": 0,
        "benefit": "Significant monthly cost savings",
        "desc": "At Jade Solar, we're committed to finding a solution where you'll end up paying less for solar than you currently do for your electricity bill! Imagine saving a bundle by slashing your utility costs, maybe even eliminating them entirely. It's more than just saving money—it's an investment that keeps on giving back, whether you're a homeowner or running a business."
    },
    {
        "id": 1,
        "benefit": "Increased property value",
        "desc": ""
    },
    {
        "id": 2,
        "benefit": "Government incentives and rebates",
        "desc": ""
    },
    {
        "id": 3,
        "benefit": "Positive enviromental impact",
        "desc": ""
    },
    {
        "id": 4,
        "benefit": "Energy independence",
        "desc": ""
    },
]

export default benefits;