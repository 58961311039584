import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';


const drawerWidth = 240;
const navItems = [<a style={{ color: '#F6F1F1' }} href="#intro">Home</a>,
<a style={{ color: '#F6F1F1' }} href="#benefits">Benefits</a>
    , <a style={{ color: '#F6F1F1' }} href="#about-div">About</a>, <a style={{ color: '#F6F1F1' }} href="#contact">Contact</a>];

window.onscroll = () => {
    if (window.scrollY > 0) {
      document.getElementById("nav-bar").setAttribute("style", "background: #146C94;");
      document.getElementById("tool-bar").setAttribute("style", "padding: 1rem;");
    } else {
        document.getElementById("nav-bar").setAttribute("style", "background: transparent; box-shadow: none;");
        document.getElementById("tool-bar").setAttribute("style", "padding: 2rem;");
    }
};

export default function DrawerAppBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box className="light-text" onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                JADE SOLAR
            </Typography>
            <Divider />
            <List sx={{ margin: '20vh 0' }}>
                {navItems.map((item) => (
                    <ListItem key={JSON.stringify(item)} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

            <Button id="form-submit" variant="contained" sx={{ backgroundColor: '#19A7CE' }}>
                <a className="light-text" href="#first-name-input">GO SOLAR</a>
            </Button>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box className="light-text" sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar id="nav-bar" component="nav" style={{background: 'transparent', boxShadow: 'none'}}>
                <Toolbar id="tool-bar" style={{ padding: '2rem' }}>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { sm: 'block', md: 'block', fontSize: '25px' } }}
                    >
                        JADE SOLAR
                    </Typography>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' }, marginLeft: 'auto' }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        {navItems.map((item) => (
                            <Button key={JSON.stringify(item)} sx={{ color: '#fff', margin: '0vw 1vw' }}>
                                {item}
                            </Button>
                        ))}

                        <Button id="form-submit" variant="contained" sx={{ backgroundColor: '#19A7CE' }}>
                            <a className="light-text" href="#first-name-input">GO SOLAR</a>
                        </Button>
                    </Box>

                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    anchor='right'
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    PaperProps={{
                        sx: {
                            backgroundColor: "#146C94"
                        }
                    }}
                    sx={{
                        display: { sm: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }

                    }}
                >
                    {drawer}
                </Drawer>
            </nav>

        </Box>
    );
}