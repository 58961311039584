import React from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import steps from "../constants/steps";

function ProcessStep(props){
    return(
        <div className="process-step">
            <div>
                <h3>{props.stepNum}</h3>
                <h5>{props.stepInfo}</h5>
            </div>
            {!props.lastStep && <ChevronRightIcon sx={{display:'inline', margin: 'auto 10px', transform: 'scale(1.8)', color: '#19A7CE'}}/>}
        </div>
    );
}

export default function Process(){
    return(
        <div id="process-flex">
            {steps.map((step, index) => {return <ProcessStep key={step.num} stepNum={step.num} stepInfo={step.info} lastStep={steps.length - 1 === index ? true: false}/>})}        
        </div>
    );
}