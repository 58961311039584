const sources = [
    {
        "id": 0,
        "source": "Facebook"
    },
    {
        "id": 1,
        "source": "Google"
    },
    {
        "id": 2,
        "source": "Instagram"
    },
    {
        "id": 4,
        "source": "Local Advertisment"
    },
    {
        "id": 5,
        "source": "Other"
    },
]

export default sources;